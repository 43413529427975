

import { Link } from "react-router-dom";
import ProfileDropdown from "./profileDropdown";
import { useLocation } from "react-router-dom";
import React, { useContext } from "react";
import { GlobalContext } from "../../../context/Provider";
import { ROLES_BACKUP, ROLES_CONTACT_US, ROLES_CREATOR, ROLES_TRANSACTION, ROLES_VEHICLE, ROLES_BOOKING, ROLES_STREAMING, DIVIDEND_MANAGEMENT, ROLES_DASHBOARD, ROLES_FAQS, ROLES_RIDES, ROLES_NOTIFICATION, ROLES_POLICIES, ROLES_REPORTS, ROLES_SALES, ROLES_USERS, TRADE_ORDERS, AUCTION_ORDERS, ROLES_CREATED, ROLES_RIDEREPORT, ROLES_COUPONS, ROLES_COLLECTION, ROLES_TRANSFER, ROLES_SAVED } from "../../../constants/actionTypes";


export default (props: any) => {
  const { authState, staticDataState } = useContext(GlobalContext);
  const roles = (Array.isArray(authState.roles) ? authState.roles : [])

  let location = useLocation();

  console.log(staticDataState, "staticDataState");

  const MenuItems = [
    // { name: "Language", path: "/language", icon: "fas fa-language" },
    { name: "Booked Rides", path: "/booked-rides/1", matchpath: '/booked-rides', icon: "fas fa-folder", role: ROLES_BOOKING },
    { name: "Saved Rides", path: "/saved-rides/1", matchpath: '/saved-rides', icon: "fas fa-folder", role: ROLES_SAVED },
    { name: "Vehicles", path: "/vehicles/1", matchpath: '/vehicle', icon: "fas fa-folder", role: ROLES_VEHICLE },
    { name: "Transactions", path: "/transactions/1", matchpath: '/transactions', icon: "fas fa-folder", role: ROLES_TRANSACTION },
    { name: "Transfer", path: "/transfer/1", matchpath: '/transfer', icon: "fas fa-folder", role: ROLES_TRANSFER },
    { name: "Coupons", path: "/coupons/1", matchpath: '/coupons', icon: "fas fa-folder", role: ROLES_COUPONS },

    { name: "Created Rides", path: "/created-rides/1", matchpath: '/created-rides', icon: "fas fa-folder", role: ROLES_RIDES },
    { name: "Report User", path: "/report-user/1", matchpath: '/report-user', icon: "fas fa-folder", role: ROLES_RIDEREPORT },
    { name: "FAQs", path: ("/FAQs/1").toLocaleLowerCase(), matchpath: '/FAQs', icon: "fas fa-question-circle", role: ROLES_FAQS },
    { name: "Contents", path: "/contents", matchpath: '/contents', icon: "fas fa-folder", role: ROLES_POLICIES },
    { name: "Contact Us", path: "/contact-us/1", matchpath: '/contact-us', icon: "fas fa-address-book", role: ROLES_CONTACT_US, pending_count: staticDataState?.dashboard?.total_pending_contact_us },
    // { name: "NFTs Reports", path: "/reports/nft/1", icon: "fas fa-address-book", role: ROLES_REPORTS },
    // { name: "Users Reports", path: "/reports/users/1", icon: "fas fa-address-book", role: ROLES_REPORTS },
    { name: "Notifications", path: "/notifications", matchpath: '/notifications', icon: "fas fa-bell", role: ROLES_NOTIFICATION },

    // { name: "Email Templates", path: "/email-template", icon: "fa fa-th-large" },

  ];



  return (
    <nav
      className={`navbar-default navbar-static-side ${props.isVisible === true ? "open-nav" : ""}`}
      role="navigation"
    >
      <div className="sidebar-collapse">
        <div className="arrow d-md-none" onClick={props.toggle}>
          <i className="fa fa-times"></i>
        </div>
        <ul className="nav" id="side-menu">
          <li className="nav-header  text-center">
            <ProfileDropdown logOutNow={props.logOutNow} roles={roles} />
            <div className="logo-element">IN+</div>
          </li>
          {(roles.includes(ROLES_DASHBOARD) || authState.super_admin) ?
            <li className={location.pathname === ("/") ? "active" : ""}>
              <Link to="/">
                <i className="fa fa-th-large"></i> <span className="nav-label">Dashboard</span>
              </Link>
            </li> : ''}
          {(roles.includes(ROLES_USERS) || authState.super_admin) ?
            <li className={location.pathname.includes("/users") ? "active" : ""}>
              <Link to="/users/1">
                <div className="justify-content-between d-flex">

                  <div className="">
                    <i className="fas fa-user"></i> <span className="nav-label">Users</span>
                  </div>
                  <div className="fw-bold" style={{ fontSize: "14px" }}>
                    ({staticDataState?.dashboard?.total_docs_verified ? staticDataState?.dashboard?.total_docs_verified : 0})

                  </div>
                </div>
              </Link>
            </li> : ''}

          {MenuItems.map((res: any) => {
            return (roles.includes(res.role) || authState.super_admin) ?
              < li className={location.pathname.startsWith(res.matchpath) ? "active" : ""} >
                <Link to={res.path}>
                  <div className="justify-content-between d-flex">
                    <div className="">
                      <i className={res.icon}></i> <span className="nav-label">{res.name}</span>
                    </div>
                    {res.pending_count >= 0 ? <div className="fw-bold" style={{ fontSize: "14px" }}>
                      ({res?.pending_count ? res?.pending_count : 0})
                    </div> : ""}
                  </div>
                </Link>
              </li> : ''
          })}

          {(roles.includes(ROLES_BACKUP) || authState.super_admin) ?
            <li className={location.pathname.includes("/db-backup") ? "active" : ""}>
              <Link to="/db-backup" className="staminggg sidebar-text">
                <i className="fas fa-hdd"></i> <span className="nav-label">DB Backup</span>
              </Link>
            </li> : ''}
          {/* {(roles.includes(ROLES_BACKUP) || authState.super_admin) ?
                    <li className={location.pathname.includes("/db-clear") ? "active" : ""}>
                      <Link to="/db-clear" className="staminggg sidebar-text">
                        <i className="fas fa-hdd"></i> <span className="nav-label">DB Clear</span>
                      </Link>
                    </li> : ''} */}
          {/* </ul> */}
          {/* </li>
            </ul> */}
          {/* </aside> */}
        </ul>
      </div>
    </nav >
  );
}