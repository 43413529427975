import React from 'react';
import PaginationLayout from "../components/PaginationLayout";
import profile_placeholder from "../assets/images/profile_placeholder.png";
import { GlobalContext } from "../context/Provider";
import { useContext, useEffect, useState } from "react";
import henceforthApi from "../utils/henceforthApi";
interface driverDetail {
    name: string
}
interface ridebook {
    city_from: string
    booking_status: string
    city_to: string
    total_price: number
    booked_seats: number
    driver_detail: driverDetail
    id: string
}
function SavedRides({ _id }: any) {
    const [page, setPage] = useState<number>(1)
    const [state, setState] = useState({
        data: {
            data: [] as Array<ridebook>,
            total_count: 0
        }
    })
    const [loading, setLoading] = useState(false);
    let limit = 10;
    const { authState } = useContext(GlobalContext);
    if (authState.access_token) { henceforthApi.setToken(authState.access_token); }
    const onChangePagination = (newVal: number) => {
        setPage(Number(newVal))
    };
    const getSavedRides = async () => {
        const bookDetail = await henceforthApi.User.savedRides(page - 1, limit)
        setState(bookDetail)
    }
    console.log(state)
    useEffect(() => {
        getSavedRides()
    }, [page])
    return (
        <div className='row'>
            <div className="ibox">
                <div className="ibox-title">
                    <h5>Saved Rides</h5>
                </div>
                <div className="ibox-content">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Sr No</th>
                                    <th>User Name</th>
                                    <th>Email</th>
                                    <th>Country</th>
                                    <th>Pick up Location </th>
                                    <th>Drop off Location</th>
                                    <th>Date</th>
                                    <th>Phone No.</th>
                                </tr>
                            </thead>
                            {state.data.total_count != 0 ?
                                <>
                                    <tbody>
                                        {state.data.data.map((res: any, index: number) => {
                                            return (
                                                <tr key={res.id} className='py-8' style={{ padding: '0px 20px' }}>

                                                    <td>
                                                        {page === 0
                                                            ? index + 1
                                                            : (page - 1) * limit + (index + 1)}
                                                        .
                                                    </td>
                                                    <td>
                                                        <div className="text-nowrap">
                                                            <img
                                                                src={
                                                                    res?.user_id?.profile_pic !== 'null' && res?.user_id?.profile_pic !== null ? henceforthApi.FILES.imageOriginal(res?.user_id?.profile_pic) : profile_placeholder
                                                                }
                                                                className="me-2 imageSize" alt="" />
                                                            {res?.user_id?.name ? `${res?.user_id?.name} ` : `Not Available`}
                                                        </div>
                                                        {/* <div className="text-nowrap">
                                                            {res?.user_id?.name
                                                                ? res?.user_id?.name : "Not available"}
                                                        </div> */}
                                                    </td>
                                                    <td>
                                                        <div className="text-nowrap">
                                                            {res?.user_id?.email
                                                                ? res?.user_id?.email : "Not available"}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="text-nowrap">
                                                            {res?.user_id?.country
                                                                ? res?.user_id?.country : "Not available"}
                                                        </div>
                                                    </td>
                                                    <td title={res?.address_to}>
                                                        {res?.address_to?.length > 25 ? res?.address_to.slice(0, 25) + '...' : res?.address_to || "Not available"}
                                                    </td>
                                                    <td title={res?.address_from}>
                                                        {res?.address_from?.length > 25 ? res?.address_from.slice(0, 25) + '...' : res?.address_from || "Not available"}
                                                    </td>
                                                    <td>
                                                        {res?.date ? res?.date : "Not available"}
                                                    </td>
                                                    <td>
                                                        {res?.user_id?.phone_no == 0 ? "Not available" : res?.user_id?.country_code + ' ' + res?.user_id?.phone_no}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    {state.data?.total_count > 10 ?
                                        <div className="view-more mb-2">
                                            <div className="d-flex align-items-center justify-content-center">
                                                {/* <Link
                            to="/booked-rides/1"
                            className="btn btn-xs btn-white me-2"
                          >
                            View More
                          </Link> */}
                                            </div>
                                        </div> : ""
                                    }
                                </> :
                                <tbody >
                                    <td colSpan={6}>
                                        <div className="row mt-4"><div className="col-12 text-center"><b className="text-center ms-5">Sorry ! no data to display</b></div></div>
                                    </td>
                                </tbody>}
                        </table>
                        <PaginationLayout
                            count={state.data.total_count}
                            data={state.data.data}
                            page={page}
                            limit={Number(limit)}
                            loading={loading}
                            onPageChange={(val) => onChangePagination(val)}
                        />
                        <div className="view-more mb-2">
                            <div className="d-flex align-items-center justify-content-center">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SavedRides
